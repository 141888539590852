import React, { Component, FormEvent } from "react";
import {
  StripeProvider,
  Elements,
  CardElement,
  PaymentRequestButtonElement,
  injectStripe,
  ReactStripeElements,
} from "react-stripe-elements";
import moment from "moment-timezone";
import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import Loader from "react-loaders";
import { Input } from "reactstrap";
import styles from "./styles.scss";
import { Button } from "src/components/Button";
import { TierItem } from "src/typings/pricing";
import { getSportChangeRange } from "src/services/DateService";
import { SubscriptionContext } from "src/services/SubscriptionService";
import { CREATE_USER_SUBSCRIPTION } from "src/gql/mutations";
import { STRIPE_COUPON_QUERY } from "src/gql/queries";
import { getFreeTrialClaimed } from "src/services/UserService";
import AnalyticsService from "src/services/AnalyticsService";
import AdZoneService, { AdZoneEnum } from "src/services/AdZoneService";
import { loadStripe } from "@stripe/stripe-js/pure";
import { DEFAULT_TIMEZONE, STATES } from "src/constants";
import Dropdown from "src/components/Dropdown";

const FoamFingerIcon = require("public/icon/foam-finger@3x.png?sizes[]=42,sizes[]=84,sizes[]=126");

export interface CheckoutViewProps {
  style?: any;
  sportList: string[];
  className?: string;
  selectedTier: TierItem;
  upsellTier?: TierItem;
  slideNext(): void;
  toggleModal(): void;
  toggleDisabled(): void;
  couponId?: string;
  setInvoice(value: string): void;
}

type CheckoutViewInternal = WithApolloClient<CheckoutViewProps>;

export interface CheckoutViewState {
  stripe?: any;
  useUpgradeTier: boolean;
  couponCode?: string;
  couponId?: string;
  couponError?: "";
  couponApplied: boolean;
  couponAmountOff?: number;
  couponPercentOff?: number;
}

export interface CardFormProps {
  stripe?: any;
  toggleDisabled(): void;
  couponId?: string;
  selectedSports: string[];
  selectedTier: TierItem;
  slideNext(): void;
  couponAmountOff?: number;
  couponPercentOff?: number;
  setInvoice(value: string): void;
}

type CardFormInternal = WithApolloClient<CardFormProps & ReactStripeElements.InjectedStripeProps>;

export interface CardFormState {
  paymentInterfaceVisible: boolean;
  canMakePayment: boolean;
  paymentRequest: any;
  loading: boolean;
  error: string;
  cardholderName?: string;
  billingAddress: {
    address?: string;
    state?: string;
    city?: string;
    zip?: string;
  };
  checkoutTerms: boolean;
  isSubmitting: boolean;
}

class _CardForm extends React.Component<CardFormInternal, CardFormState> {
  static contextType = SubscriptionContext;
  constructor(props: CardFormInternal) {
    super(props);
    this.state = {
      paymentInterfaceVisible: false,
      canMakePayment: false,
      paymentRequest: null,
      loading: false,
      error: "",
      cardholderName: "",
      billingAddress: {
        address: "",
        city: "",
        state: STATES?.[0]?.value,
        zip: "",
      },
      checkoutTerms: false,
      isSubmitting: false,
    };
  }

  // Run below on mount and update
  componentDidMount() {
    this.forceUpdate();
  }
  componentDidUpdate(prevProps: CardFormInternal) {
    if (prevProps.selectedTier.key !== this.props.selectedTier.key && !this.state.paymentInterfaceVisible) {
      this.state.paymentRequest?.update({
        total: {
          label: `${this.props.selectedTier.title} ${this.props.selectedTier.durationTitle}`,
          amount: Math.round(this.props.selectedTier.totalPrice * 100),
        },
      });
    }

    if (this.props.stripe !== null && prevProps.stripe === null) {
      const paymentRequest = this.props.stripe.paymentRequest({
        // requestPayerName: true,
        country: "US",
        currency: "usd",
        total: {
          label: `${this.props.selectedTier.title} ${this.props.selectedTier.durationTitle}`,
          amount: Math.round(this.props.selectedTier.totalPrice * 100),
        },
      });

      paymentRequest.on("token", async ({ complete, token }: any) => {
        this.props.toggleDisabled();
        const { selectedSports, selectedTier } = this.props;
        if (selectedTier.maxSports !== "all" && selectedSports.length !== selectedTier.maxSports) {
          this.props.toggleDisabled();
          return;
        }
        try {
          if (!token) {
            throw new Error("Invalid card information provided.");
          }
          await this.chargeCustomer(token.id);
          complete("success");
        } catch (e: any) {
          console.log("Payment Request error: ", e.message);
          this.setState({ error: e.message });
          complete("fail");
        }
        this.props.toggleDisabled();
      });

      paymentRequest.on("cancel", () => {
        this.setState({ paymentInterfaceVisible: false });
      });

      paymentRequest.canMakePayment().then((result: any) => {
        this.setState({ canMakePayment: !!result });
      });

      // This is out of scope for this ticket
      // eslint-disable-next-line
      this.setState({ paymentRequest });
    }
  }

  generateErrorMessage = (errors: any) => {
    if (errors?.[0]) {
      return errors[0].message;
    }
    return null;
  };

  handleChange = (change: ReactStripeElements.ElementChangeResponse) => {
    this.setState({
      error: (change && change.error && change.error.message) || "",
    });
  };

  handleSubmit = async (ev: FormEvent) => {
    if (this.isPurchaseDisabled()) {
      return AnalyticsService.track("Purchase - Disabled", {});
    }
    ev.preventDefault();
    this.props.toggleDisabled();
    if (this.props.stripe && !this.state.isSubmitting) {
      this.setState({ isSubmitting: true });
      const { selectedSports, selectedTier } = this.props;
      if (selectedTier.maxSports !== "all" && selectedSports.length !== selectedTier.maxSports) {
        this.props.toggleDisabled();
        this.setState({ isSubmitting: false });
        return;
      }
      const { billingAddress = { address: "", city: "", state: "", zip: "" }, cardholderName } = this.state;

      const { address, city, state, zip } = billingAddress;
      const parsedBillingAddress = `${address}, ${city}, ${state}, ${zip}`;

      try {
        const addresser = await import("addresser");
        // @ts-ignore Ignore broken addresser type addressLine2
        const { addressLine1, addressLine2, placeName, stateName, zipCode } =
          addresser.parseAddress(parsedBillingAddress);
        if (!zipCode) {
          throw new Error("Valid US zip code required.");
        }
        const { token } = await this.props.stripe.createToken({
          name: cardholderName,
          address_line1: addressLine1,
          address_line2: addressLine2,
          address_city: placeName,
          address_state: stateName,
          address_zip: zipCode,
          address_country: "US",
        });
        if (!token) {
          throw new Error("Invalid card information provided.");
        }
        await this.chargeCustomer(token.id);

        if (window?.ReactNativeWebView) {
          window?.ReactNativeWebView?.postMessage(token.id);
        }
      } catch (e: any) {
        // addresser does a simple throw (no error.message)
        if (!e.message || e.message.includes("zip")) {
          AnalyticsService.track("Invalid Billing Address", { parsedBillingAddress });
        }
        let error = e.message ?? "Valid US city, state, and zip required";
        console.error(error);
        this.setState({ error });
      } finally {
        this.setState({ isSubmitting: false });
      }
    } else if (!this.props.stripe) {
      console.log("Stripe.js hasn't loaded yet.");
      this.setState({ loading: false });
    }
    this.props.toggleDisabled();
  };

  chargeCustomer = async (stripeToken: string) => {
    const { couponId, selectedSports, selectedTier, setInvoice } = this.props;
    const trackingProps = {
      screen: "CheckoutModal",
      page: "Pricing",
      tab: selectedTier.durationTitle,
      subscriptionLevel: `${selectedTier.title} ${selectedTier.durationTitle}`,
      sports: selectedSports.length ? selectedSports.toString() : "All",
      upgradeSource: this.context.subscriptionUpgradeSource,
      state: this.state.billingAddress.state,
    };
    this.setState({ loading: true });
    try {
      let key = "stripeCreateUserSubscription",
        subRes: any;

      subRes = await this.props.client?.mutate({
        mutation: CREATE_USER_SUBSCRIPTION,
        variables: {
          subscriptionName: selectedTier.key,
          couponId,
          stripeToken: stripeToken,
          sports: selectedTier.maxSports !== "all" ? selectedSports : null,
        },
        errorPolicy: "all",
        awaitRefetchQueries: true,
        refetchQueries: () => ["pricingPageQuery"],
      });

      const { data: subData, errors: subErrors } = subRes;
      if (subErrors || !subData?.[key]) {
        const errorMessage = this.generateErrorMessage(subErrors);
        throw new Error(errorMessage || "Failed to create user subscription.");
      }
      const { token, invoiceId } = subData[key];
      // set the subscriptionLevel globally
      await this.context.setSubscriptionLevel(token);
      this.props.slideNext();
      setInvoice(invoiceId);
      this.setState({ loading: false });
      AnalyticsService.track("Purchase", {
        ...trackingProps,
        price: selectedTier.totalPrice,
        currency: "USD",
        tierKey: selectedTier.key,
        success: true,
      });
    } catch (e: any) {
      console.log("Purchase failed: ", e.message);
      this.setState({ loading: false, error: e.message });
      AnalyticsService.track("Purchase", {
        ...trackingProps,
        success: false,
      });
    }
  };

  isPurchaseDisabled = () => {
    const { loading, checkoutTerms, isSubmitting } = this.state;
    return loading || !checkoutTerms || isSubmitting;
  };

  render() {
    const { couponId, couponAmountOff, couponPercentOff, selectedTier } = this.props;
    const { error, loading, checkoutTerms, isSubmitting } = this.state;
    let totalPrice: any = this.props.selectedTier.totalPrice;
    let discount: any = null;
    if (couponAmountOff) {
      discount = couponAmountOff / 100;
      totalPrice = (this.props.selectedTier.totalPrice - discount).toFixed(2);
    }
    if (couponPercentOff) {
      discount = (this.props.selectedTier.totalPrice * couponPercentOff) / 100;
      totalPrice = (this.props.selectedTier.totalPrice - discount).toFixed(2);
    }
    if (totalPrice < 0) {
      totalPrice = "0.00";
    }

    return (
      <form onSubmit={this.handleSubmit}>
        {error ? <div className="rotoql-content-checkoutview__stripe-error-text">{error}</div> : null}
        <CardElement
          className="CardElement"
          onChange={this.handleChange}
          hidePostalCode={true}
          style={{
            base: {
              "::placeholder": {
                color: "#cacaca",
              },
            },
            invalid: {
              color: "#FF4F4D",
            },
          }}
        />
        <Input
          id="name"
          type="text"
          value={this.state.cardholderName}
          placeholder="Cardholder name"
          className="rotoql-content-checkoutview__text-input"
          onChange={(e) => this.setState({ cardholderName: e.target.value })}
          required
        />

        <Input
          id="address"
          type="text"
          value={this.state.billingAddress.address}
          placeholder="Address"
          className="rotoql-content-checkoutview__text-input"
          onChange={(e) =>
            this.setState((prevState) => ({
              billingAddress: {
                ...prevState.billingAddress,
                address: e.target.value,
              },
            }))
          }
          required
        />

        <Input
          id="city"
          type="text"
          value={this.state.billingAddress.city}
          placeholder="City"
          className="rotoql-content-checkoutview__text-input"
          onChange={(e) =>
            this.setState((prevState) => ({
              billingAddress: {
                ...prevState.billingAddress,
                city: e.target.value,
              },
            }))
          }
          required
        />

        <div className="rotoql-content-checkoutview__flex-container">
          <Dropdown
            toggleClassName="state-dropdown"
            items={STATES}
            selectedIndex={STATES.findIndex((o) => o.value === this.state.billingAddress?.state ?? null)}
            onSelectItem={(e) => {
              this.setState((prevState) => ({
                billingAddress: {
                  ...prevState.billingAddress,
                  state: e.value,
                },
              }));
            }}
          />

          <Input
            id="zip"
            type="text"
            value={this.state.billingAddress.zip}
            placeholder="Zip Code"
            className="rotoql-content-checkoutview__text-input"
            onChange={(e) =>
              this.setState((prevState) => ({
                billingAddress: {
                  ...prevState.billingAddress,
                  zip: e.target.value,
                },
              }))
            }
            required
          />
        </div>

        {discount ? (
          <div className="rotoql-content-checkoutview__discount-text">
            Original Price: ${this.props.selectedTier.totalPrice.toFixed(2)}
            <br />
            <span className="rotoql-content-checkoutview__discount-text--green">
              Discount: ${discount.toFixed(2)}
              {couponPercentOff ? ` (${couponPercentOff}% OFF)` : ""}
            </span>
            <br />
            {/* TODO: remove this later since it's one off code */}
            <span className="rotoql-content-checkoutview__discount-text--green">
              {couponId === "VIP40" ? "*Discount applicable for first three months only" : null}
            </span>
          </div>
        ) : null}

        <div className="rotoql-content-checkoutview__disclaimer-text">
          <input
            type="checkbox"
            className="rotoql-content-checkoutview__checkbox"
            checked={checkoutTerms}
            onChange={() => this.setState({ checkoutTerms: !checkoutTerms })}
          />
          By checking this box I agree to pay the {selectedTier.durationUnit === "year" ? "annual" : "monthly"} charge
          below, which will auto-renew every {selectedTier.durationUnit} until cancelled
        </div>

        <Button
          className={`rotoql-content-checkoutview__pay-button ${this.isPurchaseDisabled() ? "disabled" : ""}`}
          disabled={this.isPurchaseDisabled()}
        >
          {loading || isSubmitting ? (
            <Loader type="ball-pulse" active />
          ) : getFreeTrialClaimed() ? (
            `Pay \$${totalPrice}`
          ) : (
            "Start Free Trial"
          )}
        </Button>
        {this.state.canMakePayment ? (
          <PaymentRequestButtonElement
            paymentRequest={this.state.paymentRequest}
            className="PaymentRequestButton"
            style={{
              // For more details on how to style the Payment Request Button, see:
              // https://stripe.com/docs/elements/payment-request-button#styling-the-element
              paymentRequestButton: {
                theme: "dark",
                height: "35px",
              },
            }}
            onClick={() => {
              this.setState({ paymentInterfaceVisible: true });
            }}
          />
        ) : null}
      </form>
    );
  }
}
const CardForm = withApollo<CardFormProps>(injectStripe<CardFormProps>(_CardForm));

/**
 * Render the authenticatino view for the CheckoutModal component
 */
export default withApollo<CheckoutViewInternal>(
  class CheckoutView extends Component<CheckoutViewInternal, CheckoutViewState> {
    stripeScript: any;
    static defaultProps: {} = {};
    constructor(props: CheckoutViewInternal) {
      super(props);
      this.state = {
        stripe: null,
        useUpgradeTier: false,
        couponApplied: false,
        couponCode: props.couponId
          ? props.couponId
          : props.selectedTier.autoCoupon &&
            moment
              .tz(DEFAULT_TIMEZONE)
              .isBetween(
                AdZoneService.getStartDate(AdZoneEnum.PRICING_MODAL_BANNER),
                AdZoneService.getEndDate(AdZoneEnum.PRICING_MODAL_BANNER)
              )
          ? props.selectedTier.autoCoupon
          : undefined,
      };
    }

    async componentDidMount() {
      await loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!);

      // Create Stripe instance once Stripe.js loads
      this.setState(
        {
          stripe: window.Stripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!),
        },
        async () => {
          if (this.state.couponCode) {
            await this.getCoupon();
          }
        }
      );
    }

    generateErrorMessage = (errors: any) => {
      if (errors?.[0]) {
        return errors[0].message;
      }
      return null;
    };

    selectUpsell = () => {
      this.setState({ useUpgradeTier: true });
    };

    getCoupon = async () => {
      const { couponCode } = this.state;
      if (!couponCode) {
        return;
      }
      this.setState({ couponError: undefined });
      try {
        const res: any = await this.props.client?.query({
          query: STRIPE_COUPON_QUERY,
          variables: {
            couponId: couponCode,
          },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        });
        const { data, errors } = res;
        if (errors || !data || !data || !data.stripeCoupon || !data.stripeCoupon.valid) {
          const errorMessage = this.generateErrorMessage(errors);
          throw new Error(errorMessage || "Invalid coupon.");
        }
        const { couponId, amountOff, percentOff } = data.stripeCoupon;
        this.setState({
          couponId: couponId ? couponId : undefined,
          couponApplied: true,
          couponAmountOff: amountOff ? amountOff : undefined,
          couponPercentOff: percentOff ? percentOff : undefined,
        });
      } catch (e: any) {
        console.log(e.message);
        this.setState({ couponError: e.message });
      }
    };

    render() {
      const { sportList, selectedTier, upsellTier, style, className, slideNext, toggleDisabled, setInvoice } =
        this.props;
      const { couponId, couponCode, couponError, couponApplied, useUpgradeTier, couponAmountOff, couponPercentOff } =
        this.state;
      const displayTier = useUpgradeTier && upsellTier ? upsellTier : selectedTier;
      return (
        <div className={`rotoql-content-checkoutview ${className}`} style={style}>
          <div className="rotoql-content-checkoutview__body">
            <div className="rotoql-content-checkoutview__left-container">
              {upsellTier ? (
                <React.Fragment>
                  <div className="rotoql-content-checkoutview__extra-value-text">Get Extra Value With</div>
                  <div className="rotoql-content-checkoutview__upsell-container">
                    <img
                      src={FoamFingerIcon.src}
                      srcSet={FoamFingerIcon.srcSet}
                      alt="Foam Finger Icon"
                      className="rotoql-content-checkoutview__upsell-image"
                    />
                    <div className="rotoql-content-checkoutview__upsell-info-container">
                      <div className="rotoql-content-checkoutview__upsell-title">
                        {upsellTier.title} {upsellTier.durationTitle}
                      </div>
                      <div className="rotoql-content-checkoutview__upsell-duration-range-text">
                        {getSportChangeRange(upsellTier)}
                      </div>
                      {upsellTier.upsellText ? (
                        <div className="rotoql-content-checkoutview__upsell-compare-text">{upsellTier.upsellText}</div>
                      ) : null}
                    </div>
                    <Button
                      disabled={upsellTier === displayTier}
                      onClick={() => this.selectUpsell()}
                      className={`rotoql-content-checkoutview__upsell-button ${
                        upsellTier === displayTier ? "rotoql-content-checkoutview__upsell-button--applied" : ""
                      }`}
                    >
                      {upsellTier === displayTier ? "APPLIED" : "UPGRADE"}
                    </Button>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
            <div className="rotoql-content-checkoutview__divider" />
            <div className="rotoql-content-checkoutview__summary-container">
              <div className="rotoql-content-checkoutview__summary-text">Order Summary</div>
              <div className="rotoql-content-checkoutview__summary-tier-text">
                {displayTier.title} {displayTier.durationTitle}
              </div>
              {sportList.length ? (
                <div className="rotoql-content-checkoutview__sport-list">
                  {displayTier.maxSports !== "all" &&
                    sportList.map((sport: string, index: number) => (
                      <span key={index}>
                        {index !== 0 ? "/" : null}
                        {sport}
                      </span>
                    ))}
                </div>
              ) : null}
              <div className="rotoql-content-checkoutview__duration-range-text">{getSportChangeRange(displayTier)}</div>
              {couponError ? <div className="rotoql-content-checkoutview__stripe-error-text">{couponError}</div> : null}
              <div className="rotoql-content-checkoutview__coupon-input-container">
                <Input
                  type="text"
                  name="coupon"
                  value={couponCode}
                  placeholder="Discount code"
                  disabled={couponApplied}
                  onChange={(e) =>
                    this.setState({
                      couponError: undefined,
                      couponCode: e.target.value.toUpperCase(),
                    })
                  }
                  className="rotoql-content-checkoutview__coupon-input"
                />
                <Button
                  disabled={couponApplied}
                  onClick={this.getCoupon}
                  className={`rotoql-content-checkoutview__coupon-apply-btn ${
                    couponApplied ? "rotoql-content-checkoutview__coupon-apply-btn--applied" : ""
                  }`}
                >
                  {couponApplied ? "Applied" : "Apply"}
                </Button>
              </div>
              <StripeProvider stripe={this.state.stripe}>
                <Elements>
                  <CardForm
                    setInvoice={setInvoice}
                    selectedSports={sportList}
                    selectedTier={displayTier}
                    slideNext={slideNext}
                    toggleDisabled={toggleDisabled}
                    couponId={couponApplied ? couponId : undefined}
                    couponAmountOff={couponAmountOff}
                    couponPercentOff={couponPercentOff}
                  />
                </Elements>
              </StripeProvider>
            </div>
          </div>
          <style jsx>{styles}</style>
        </div>
      );
    }
  }
);

import { makeVar } from "@apollo/client";
import { MouseEvent } from "react";
import { DEFAULT_SPORT, IS_CLIENT, SPORTS_MAP, WebSportItem } from "src/constants";
import { getVariationId } from "./VariationService";

/**
 * Safely get the value of a query param to initialize reactive vars with the
 * correct value
 *
 * @param key query param key
 * @returns {string} the value of the query param
 */
function _getClientQueryParam(key: string) {
  let initialVal: string = "";
  if (IS_CLIENT) {
    const val = window.__NEXT_DATA__.query[key];
    if (val && typeof val === "string") {
      initialVal = val;
    }
  }
  return initialVal;
}

export const currentSport = makeVar<WebSportItem>(SPORTS_MAP[_getClientQueryParam("sport")] ?? DEFAULT_SPORT);
export const sportCountMismatch = makeVar<boolean>(false);
export const navBarPosition = makeVar<string | null>(null);
export const navBarLeague = makeVar<string | null>(null);
export const betMGMDismissedVar = makeVar<boolean>(false);
export const betMGMPendingVar = makeVar<boolean>(false);
export const freeTrialDismissedVar = makeVar<boolean>(false);
export const promoDismissedVar = makeVar<boolean>(false);
export const gamesDashboardEvent = makeVar<MouseEvent | null>(null);
export const gamesModifyStatsEvent = makeVar<MouseEvent | null>(null);
export const pricingModalDeepLink = makeVar<string>("");
export const customOfferModalOpen = makeVar<boolean>(false);
export const variationOverride = makeVar<number | null>(null);
export const variationId = makeVar<string>(getVariationId());
export const isHeadless = makeVar<string>("");

export interface ApolloSettings {
  wss: string;
  http: string;
}

/**
 * This holds the apollo configuration settings and the logic surrounding which settings are used.
 */
const LOCAL_SETTINGS: ApolloSettings = {
  wss: "wss://api-qa.betql.co/graphql",
  http: "https://api-qa.betql.co/graphql",
};

const QA_SETTINGS: ApolloSettings = {
  wss: "wss://api-qa.betql.co/graphql",
  http: "https://api-qa.betql.co/graphql",
};

const STAGING_SETTINGS: ApolloSettings = {
  wss: "wss://api-staging.betql.co/graphql",
  http: "https://api-staging.betql.co/graphql",
};

const PROD_SETTINGS: ApolloSettings = {
  wss: "wss://api.betql.co/graphql",
  http: "https://api.betql.co/graphql",
};

/**
 * Get the settings that bet is using for the apollo client
 * This returns the entire settings dictionary, so http or wss must be pulled out from it.
 */
const apolloBetSettings = () => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
    return PROD_SETTINGS;
  }
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" && process.env.NEXT_PUBLIC_GIT_BRANCH === "qa") {
    return QA_SETTINGS;
  }
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "development") {
    return LOCAL_SETTINGS;
  }
  return STAGING_SETTINGS;
};

export default apolloBetSettings;
